<template>
	<v-form ref="form" @submit.prevent="submit" class="px-2">
		<!-- inputs -->
		<v-row class="align-center mt-0 mb-5">
			<!-- product-name -->
			<v-col lg="3" md="4" sm="6" cols="12">
				<v-text-field
					v-model="name"
					:label="$t('inputs.plan-name')"
					hide-details
					outlined
					dense
					class="rounded-medium"
					:loading="loading"
					:disabled="loading || submitLoading"
					:rules="rules.required"
				></v-text-field>
			</v-col>

			<!-- duration -->
			<v-col lg="3" md="4" sm="6" cols="12">
				<v-text-field
					v-model.number="duration"
					:label="$t('inputs.duration')"
					hide-details
					outlined
					dense
					type="number"
					hide-spin-buttons
					min="0"
					class="rounded-medium"
					:loading="loading"
					:disabled="loading || submitLoading"
					:rules="rules.requiredAsNumber"
				></v-text-field>
			</v-col>

			<!-- price -->
			<v-col lg="3" md="4" sm="6" cols="12">
				<v-text-field
					v-model.number="price"
					:label="$t('inputs.price')"
					hide-details
					outlined
					dense
					type="number"
					hide-spin-buttons
					min="0"
					class="rounded-medium"
					:loading="loading"
					:disabled="loading || submitLoading"
					:rules="rules.requiredAsNumber"
				></v-text-field>
			</v-col>

			<!-- is featured -->
			<v-col lg="3" md="4" sm="6" cols="12">
				<v-switch
					v-model="isFeatured"
					:label="$t('inputs.is-featured')"
					hide-details
					outlined
					dense
					class="rounded-medium ma-0"
					:loading="loading"
					:disabled="loading || submitLoading"
				></v-switch>
			</v-col>

			<template v-if="false">
				<!-- offer price -->
				<v-col lg="3" md="4" sm="6" cols="12">
					<v-text-field
						v-model.number="offerPrice"
						:label="$t('inputs.offer-price')"
						hide-details
						outlined
						dense
						class="rounded-medium"
						:loading="loading"
						:disabled="loading || submitLoading"
					></v-text-field>
				</v-col>
				
				<!-- offer start date -->
				<v-col lg="3" md="4" sm="6" cols="12">
					<v-menu
						ref="offerStartDateMenu"
						:close-on-content-click="false"
						transition="scale-transition"
						offset-y
						min-width="auto"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="offerStartDate"
								:label="$t('inputs.offer-start-date')"
								hide-details
								outlined
								dense
								class="rounded-medium"
								prepend-inner-icon="mdi-calendar"
								readonly
								clearable
								:loading="loading"
								:disabled="loading || submitLoading"
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
							v-model="offerStartDate"
							:max="(new Date().getFullYear() + 1) + new Date().toISOString().slice(4, 10)"
							min="1950-01-01"
							@change="$refs.offerStartDateMenu.save(offerStartDate)"
						></v-date-picker>
					</v-menu>
				</v-col>
				
				<!-- offer-end-date -->
				<v-col lg="3" md="4" sm="6" cols="12">
					<v-menu
						ref="offerEndDateMenu"
						:close-on-content-click="false"
						transition="scale-transition"
						offset-y
						min-width="auto"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="offerEndDate"
								:label="$t('inputs.offer-end-date')"
								hide-details
								outlined
								dense
								class="rounded-medium"
								prepend-inner-icon="mdi-calendar"
								readonly
								clearable
								:loading="loading"
								:disabled="loading || submitLoading"
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
							v-model="offerEndDate"
							:max="(new Date().getFullYear() + 1) + new Date().toISOString().slice(4, 10)"
							min="1950-01-01"
							@change="$refs.offerEndDateMenu.save(offerEndDate)"
						></v-date-picker>
					</v-menu>
				</v-col>
			</template>
		</v-row>

		<!-- description -->
		<div class="text-body-1 font-weight-bold text-capitalize mb-3">{{$t('inputs.description')}}</div>
		<text-editor
			v-model="description"
			class="mb-5"
		></text-editor>

		<v-btn
			type="submit"
			color="primary"
			class="rounded-medium me-2"
			:loading="submitLoading"
			:disabled="loading || submitLoading"
		>
			{{$t('save')}}
		</v-btn>
	</v-form>
</template>

<script>
import rules from '@/helpers/validation rules'
import moment from 'moment'

export default {
	name: 'Plan',

	data: () => ({
		// helpers
		loading: false,
		submitLoading: false,
		rules,

		// data
		name: null,
		duration: null,
		price: null,
		isFeatured: null,
		offerPrice: null,
		offerStartDate: null,
		offerEndDate: null,
		description: null
	}),

	methods: {
		fetchPlan() {
			this.loading = true;
			this.$store.dispatch('plans/fetchBySlug', {
				slug: this.$route.params.slug,
				acceptLanguage: this.lang
			}).then((data) => {
				this.name = data.name;
				this.duration = data.duration;
				this.price = data.price;
				this.isFeatured = data.isFeatured;
				this.offerPrice = data.offerPrice;
				this.offerStartDate = data.offerStart ? moment(data.offerStart).format('YYYY-MM-DD') : null;
				this.offerEndDate = data.offerEnd ? moment(data.offerEnd).format('YYYY-MM-DD') : null;
				this.description = data.description;
			}).finally(() => {
				this.isInputsChanged = false;
				this.loading = false;
			})
		},

		submit() {
			if (this.$refs.form.validate()) {
				this.submitLoading = true;
				const actionName = this.$route.params.id ? 'update' : 'create';
				this.$store.dispatch(`plans/${actionName}`, {
					id: this.$route.params.id,
					name: this.name,
					duration: this.duration,
					price: this.price,
					offerPrice: this.offerPrice,
					offerStart: this.offerStartDate,
					offerEnd: this.offerEndDate,
					description: this.description,
					isFeatured: this.isFeatured,
				}).then(() => {
					const message =
						actionName === 'update'
							? this.$t('$-is-updated-successfully', { name: this.$t('Plans.plan') })
							: this.$t('$-is-created-successfully', { name: this.$t('Plans.plan') });
					this.$eventBus.$emit('show-snackbar', false, message);

					this.$router.push({ name: 'plans' });
				}).finally(() => {
					this.submitLoading = false;
				})
			}
		}
	},

	created() {
		if (this.$route.params.id) {
			this.fetchPlan();
		} 
	}
}
</script>

<style>

</style>