export default {
	statistics: {
		visitors: 'visitors',
		users: 'users',
		'disabled-products': 'disabled products',
		revenue: 'revenue'
	},

	descriptions: {
		visitors: 'Visitors right now',
		today: 'Login today',
		'out-of-$-products': 'Out of {number} products',
		orders: 'Orders'
	},

	'products-views': 'Products views',

	'order-status': {
		title: 'Order status',
		approved: 'Approved',
		'in-preparation': 'In preparation',
		shipping: 'Shipping',
		delivered: 'Delivered',
		unpaid: 'Unpaid'
	},

	'resent-added-products': 'Resent added products',
	'popular-products': 'Popular products'
}