var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"ripple",rawName:"v-ripple",value:(!_vm.disabled),expression:"!disabled"}],staticClass:"bee-drop-img",style:({
		width: Number(_vm.width) ? _vm.width + 'px' : _vm.width,
		height: Number(_vm.height) ? _vm.height + 'px' : _vm.height,
		borderRadius: Number(_vm.radius) ? _vm.radius + 'px' : _vm.radius,
	})},[((_vm.hoverable || _vm.blurable || _vm.clearable) && _vm.value)?_c('div',{staticClass:"bee-drop-img__hover",class:{'bee-drop-img__hover--blurable': _vm.blurable || (_vm.clearable && _vm.value)}},[(!_vm.$slots['hover-content'] && (_vm.clearable && _vm.value))?[(_vm.value && !_vm.disabled)?_c('v-btn',{attrs:{"max-width":"30px","max-height":"30px","dark":"","fab":"","color":_vm.clearColor},on:{"click":function($event){_vm.$emit('remove-image', _vm.file); _vm.$emit('input', null); _vm.file = null; _vm.isDroped = false}}},[_c('v-icon',[_vm._v(_vm._s(_vm.clearIcon))])],1):_vm._e()]:_vm._t("hover-content")],2):_vm._e(),_c('div',{staticClass:"drop-zone",class:[{ 'drop-zone--dashed': _vm.dashed }, _vm.dropZoneClasses],style:({
			borderWidth: Number(_vm.borderWidth) ? _vm.borderWidth + 'px' : _vm.borderWidth,
			borderColor: _vm.borderColor,
			cursor: _vm.disabled ? 'default' : null,
			borderRadius: Number(_vm.radius) ? _vm.radius +'px': _vm.radius
		}),on:{"click":function($event){!_vm.disabled ? _vm.$refs.fileInput.click() : null}}},[(!_vm.isDroped && !_vm.value)?_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[(!_vm.$slots.defualt && !_vm.disabled)?[_c('v-icon',{attrs:{"color":"grey","large":""}},[_vm._v(_vm._s(_vm.icon))]),(_vm.icon === 'mdi-image' ? !_vm.$slots['drop-text'] : !_vm.$slots['drag-text'])?_c('span',{staticClass:"text-center mt-2"},[_vm._v(" "+_vm._s(_vm.icon === 'mdi-image' ? _vm.dropText : _vm.dragText)+" ")]):[_vm._t(_vm.icon === 'mdi-image' ? 'drop-text' : 'drag-text')]]:[_vm._t("default")]],2):_c('div',{ref:"showImage",staticClass:"drop-zone__show-image",style:({
				backgroundSize: _vm.contain ? 'contain' : 'cover'
			})}),_c('v-fade-transition',[(_vm.loadingImage)?_c('v-progress-linear',{staticClass:"drop-zone__progress-line",attrs:{"height":"15","color":"#ccc","value":!_vm.stopLoading ? _vm.progressValue : 100,"buffer-value":_vm.stopLoading ? _vm.progressValue : 100,"stream":""}}):_vm._e()],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"absolute":"","bottom":"","indeterminate":""}}):_vm._e(),_c('input',{ref:"fileInput",staticClass:"drop-zone__input",attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.setImage(_vm.$refs.fileInput.files[0])}}}),((_vm.isDroped || (_vm.value && typeof _vm.value !== 'string')) && !_vm.hideImageName)?_c('div',{staticClass:"drop-zone__title"},[_vm._v(" "+_vm._s(_vm.file.name)+" ")]):_vm._e(),_c('div',{staticClass:"drop-zone__drop-area",on:{"drop":function($event){$event.preventDefault();!_vm.disabled ? (() => {_vm.updateImage($event); _vm.isDroped = true})() : null},"dragover":function($event){$event.preventDefault();_vm.icon = 'mdi-image'},"dragleave":function($event){_vm.icon = 'mdi-tray-arrow-down'}}})],1),(!_vm.hideDetails)?_c('div',{staticClass:"error__container"},[_c('div',{staticClass:"error__msg text-caption error--text mb-0",class:{'error__msg--show': _vm.error}},[_vm._v(" "+_vm._s(_vm.error)+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }