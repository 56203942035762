import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import dashboard from './modules/dashboard'
import sets from './modules/sets'
import categories from './modules/categories'
import filters from './modules/filters'
import keywords from './modules/keywords'
import products from './modules/products'
import news from './modules/news'
import orders from './modules/orders'
import plans from './modules/plans'
import albums from './modules/albums'
import sliders from './modules/sliders'
import users from './modules/users'
import subscriptions from './modules/subscriptions'
import aboutUs from './modules/about-us'
import SEO from './modules/SEO'
import fileManager from './modules/file-manager'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		auth,
		dashboard,
		sets,
		categories,
		filters,
		keywords,
		products,
		news,
		orders,
		plans,
		albums,
		sliders,
		users,
		subscriptions,
		aboutUs,
		SEO,
		fileManager
	}
})