import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		filters: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/filters').then((response) => {
				commit(types.STORE_FILTERS, response.data);
				return response.data;
			})
		},
		create({ commit }, { name }) {
			return axios.post('/filters', { name })
		},
		update({ commit }, { id, name }) {
			return axios.post(`/filters/update/${id}`, { name, _method: 'PUT' })
		},
		delete({ commit }, { id }) {
			return axios.post(`/filters/${id}`, { _method: 'DELETE' })
		},
		restore({ commit }, { id }) {
			return axios.patch(`/filters/${id}`)
		},
	},

	mutations: {
		[types.STORE_FILTERS](state, filters) {
			state.filters = filters;
		}
	},

	getters: {
		getFilterById: state => id => state.filters.find(c => c.id === id),
		getFilterBySlug: state => slug => state.filters.find(c => c.slug === slug),
	}
}