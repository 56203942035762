export const preview = {
	inPathName: 'preview',
	name: 'preview',
}

export const login = {
	inPathName: 'login',
	name: 'login',
}

export const dashboard = {
	inPathName: '',
	name: 'dashboard',
	show: true
}

export const sets = {
	inPathName: 'tabs',
	name: 'sets',
	show: true
}

export const categories = {
	inPathName: 'categories',
	name: 'categories',
	show: true
}

export const category = {
	inPathName: 'category',
	name: 'category',
	show: true
}

export const filters = {
	inPathName: 'filters',
	name: 'filters',
	show: true
}

export const keywords = {
	inPathName: 'tags',
	name: 'tags',
	show: true
}

export const products = {
	inPathName: 'products',
	name: 'products',
	show: true
}

export const product = {
	inPathName: 'product',
	name: 'product',
	show: true,
	isAddEditPage: true
}

export const news = {
	inPathName: 'blogs',
	name: 'news',
	show: true
}

export const newsIndex = {
	inPathName: 'blog',
	name: 'news-index',
	show: true,
	isAddEditPage: true
}

export const orders = {
	inPathName: 'orders',
	name: 'orders',
	show: true
}

export const order = {
	inPathName: 'order',
	name: 'order',
	show: true,
	isAddEditPage: false
}

export const plans = {
	inPathName: 'plans',
	name: 'plans',
	show: true
}

export const plan = {
	inPathName: 'plan',
	name: 'plan',
	show: true,
	isAddEditPage: true
}

export const albums = {
	inPathName: 'videos',
	name: 'albums',
	show: true
}

export const sliders = {
	inPathName: 'sliders',
	name: 'sliders',
	show: true
}

export const users = {
	inPathName: 'users',
	name: 'users',
	show: true
}

export const userInfo = {
	inPathName: 'user-info',
	name: 'user-info',
	show: true
}

export const subscriptions = {
	inPathName: 'subscriptions',
	name: 'subscriptions',
	show: true
}

export const aboutUs = {
	inPathName: 'about-us',
	name: 'about-us',
	show: true
}

export const SEO = {
	inPathName: 'seo',
	name: 'seo',
	show: true
}