<template>
	<div>
		<!-- about -->
		<p class="text-h6 font-weight-bold mb-4">{{$t('AboutUs.about')}}</p>
		<text-editor v-model="about" id="about"/>

		<!-- mission -->
		<template v-if="false">
			<p class="text-h6 font-weight-bold mb-4 mt-6">{{$t('AboutUs.mission')}}</p>
			<text-editor v-model="mission" id="mission"/>
		</template>

		<!-- vesion -->
		<template v-if="false">
			<p class="text-h6 font-weight-bold mb-4 mt-6">{{$t('AboutUs.vesion')}}</p>
			<text-editor v-model="vesion" id="vesion"/>
		</template>

		<v-btn
			color="primary"
			class="rounded-medium mt-6"
			:loading="submitLoading"
			:disabled="loading || submitLoading"
			@click="submit"
		>
			{{$t('save')}}
		</v-btn>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'AboutUs',

	data: () => ({
		// helpers
		loading: false,
		submitLoading: false,
		
		// data
		about: null,
		mission: null,
		vesion: null,
	}),

	computed: {
		...mapState({
			aboutUs: state => state.aboutUs.aboutUs
		})
	},

	methods: {
		submit() {
			if (this.about.length > 0) {
				this.submitLoading = true;
				this.$store.dispatch('aboutUs/create', { about: this.about })
					.then(() => {
						const message = this.$t('$-is-updated-successfully', { name: this.$t('AboutUs.about') })
						this.$eventBus.$emit('show-snackbar', false, message);
						this.$store.dispatch('aboutUs/fetchAll');
					})
					.finally(() => {
						this.submitLoading = false;
					})
			}
		},

		fillToEdit() {
			this.about = this.aboutUs.find(c => c.Key === 'about').Value;
		},
	},

	created() {
		if (this.aboutUs.length === 0) {
			this.loading = true;
			this.$store.dispatch('aboutUs/fetchAll')
				.then(() => {
					this.fillToEdit();
				})
				.finally(() => {
					this.loading = false;
				})
		} else {
			this.fillToEdit();
		}
	}
}
</script>

<style>

</style>