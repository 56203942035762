<template>
	<div>
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="albums"
			:ids="deleteItemId"
			@on-delete="fetchAlbums"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Albums.the-album')}}
		</bee-delete-dialog>

		<!-- add/edit dialog -->
		<v-dialog
			v-model="dialog"
			width="550"
			:persistent="dialogLoading"
		>
			<v-form ref="form" @submit.prevent="submit">
				<v-card class="rounded-medium">
					<v-card-title class="justify-center text-uppercase text-h6 mb-3">
						{{ editItemId ? $t('edit') : $t('add')}} {{$t('Albums.album')}}
					</v-card-title>
					<v-card-text class="pb-2">
						<v-row class="flex-column mt-0">
							<!-- title -->
							<v-col class="py-0">
								<v-text-field
									v-model="title"
									:label="$t('inputs.title')"
									outlined
									dense
									class="rounded-small"
									:rules="rules.required"
								></v-text-field>
							</v-col>
							
							<!-- description -->
							<v-col class="py-0">
								<v-textarea
									v-model="description"
									:label="$t('inputs.description')"
									outlined
									dense
									rows="2"
									auto-grow
									no-resize
									class="rounded-small"
									:rules="rules.required"
								></v-textarea>
							</v-col>
							
							<!-- video -->
							<v-col class="py-0">
								<v-text-field
									v-model="video"
									:label="$t('inputs.video-link')"
									outlined
									dense
									class="rounded-small"
									:rules="rules.required"
								></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							type="submit"
							color="primary"
							class="rounded-small"
							:loading="dialogLoading"
							:disabled="dialogLoading"
						>{{$t('save')}}</v-btn>
						<v-btn
							@click="dialog = false"
							class="rounded-small"
							:disabled="dialogLoading"
						>{{$t('cancel')}}</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>
		
		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-3">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							color="darkGreen white--text"
							class="rounded-small"
							:disabled="loading || Boolean(albums.length)"
							@click="dialog = true"
						>
							<v-icon>mdi-plus</v-icon>
							{{$t('Albums.add-new-album')}}
						</v-btn>
					</v-col>

					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : filteredAlbums"
			:loading="loading"
			:items-per-page="15"
			class="elevation-3 rounded-medium"
		>
			<!-- Link -->
			<template v-slot:item.link="{ item }">
				<a target="_blank" :href="item.link"> {{item.value}} </a>
			</template>

			<!-- description -->
			<template v-slot:item.description="{ item }">
				{{item.description | ellipsis(130)}}
			</template>

			<!-- video -->
			<template v-slot:item.video="{ item }">
				<a target="_blank" :href="item.video"> {{item.video | ellipsis(35)}} </a>
			</template>

			<template v-slot:item.actions="{item}" >
				<!-- acitons -->
				<div class="d-flex justify-center">
					<v-btn
						color="blue darken-4"
						dark
						small
						class="me-1 px-1 flex-shrink-1"
						min-width="20px"
						@click="editItemId = item.id; dialog = true;"
					><v-icon>mdi-pencil</v-icon></v-btn>
					<v-btn
						color="red darken-1"
						dark
						small
						class="px-1"
						min-width="20px"
						@click="deleteItemId = item.id; deleteDialog = true"
					>
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapGetters, mapState } from 'vuex';

export default {
	name: 'Albums',

	data: () => ({
		deleteDialog: false,
		deleteItemId: null,
		dialog: false,
		editItemId: null,

		// filter
		search: null,
		isFiltered: false,

		// helpers
		loading: false,
		dialogLoading: false,
		rules,

		// data
		title: null,
		description: null,
		video: null,
	}),

	watch: {
		dialog(val) {
			if (val) {
				if (this.editItemId) {
					const item = this.getAlbumById(this.editItemId);
					this.title = item.title;
					this.description = item.description;
					this.video = item.video;
				}
			} else {
				this.editItemId = null;
				this.title = null;
				this.description = null;
				this.video = null;
				this.$nextTick(() => {
					this.$refs.form.resetValidation();
				})
			}
		}
	},

	computed: {
		...mapState({
			albums: state => state.albums.albums
		}),
		...mapGetters({
			getAlbumById: 'albums/getAlbumById'
		}),

		headers() {
			return [
				{ text: this.$t('headers.titleVideo'), value: 'title', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.description'), value: 'description', align: 'center', width: 500 },
				{ text: this.$t('headers.linkVideo'), value: 'video', align: 'center' },
				{ text: this.$t('headers.actions'), value: 'actions', align: 'center', sortable: false },
			]
		},

		filteredAlbums() {
			return this.filterByName(this.isFiltered, this.albums, this.search);
		}
	},
	methods: {
		submit() {
			if (this.$refs.form.validate()) {
				const actionName = this.editItemId ? 'update' : 'create';
				this.dialogLoading = true;
				this.$store.dispatch(`albums/${actionName}`, {
					id: this.editItemId,
					title: this.title,
					description: this.description,
					video: this.video,
				}).then(() => {
					const message =
						this.editItemId
							? this.$t('$-is-updated-successfully', { name: this.$t('Albums.album') })
							: this.$t('$-is-created-successfully', { name: this.$t('Albums.album') });
					this.$eventBus.$emit('show-snackbar', false, message);
					this.dialog = false;
					this.fetchAlbums();
				}).finally(() => {
					this.dialogLoading = false;
				})
			}
		},

		fetchAlbums() {
			this.loading = true;
			this.$store.dispatch('albums/fetchAll')
				.finally(() => {
					this.loading = false;
				})
		}
	},

	created() {
		this.fetchAlbums();
	}
};
</script>

<style>
</style>