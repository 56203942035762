import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		albums: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/albums').then((response) => {
				commit(types.STORE_ALBUMS, response.data);
				return response.data;
			})
		},
		create({ commit }, { title, description, video }) {
			return axios.post('/albums', { title, description, video })
		},
		update({ commit }, { id, title, description, video }) {
			return axios.post(`/albums/update/${id}`, { title, description, video, _method: 'PUT' })
		},
		delete({ commit }, { id }) {
			return axios.post(`/albums/${id}`, { _method: 'DELETE' })
		},
		restore({ commit }, { id }) {
			return axios.patch(`/albums/${id}`)
		},
	},

	mutations: {
		[types.STORE_ALBUMS](state, albums) {
			state.albums = albums;
		}
	},

	getters: {
		getAlbumById: state => id => state.albums.find(c => c.id === id),
	}
}