<template>
	<v-form ref="form" @submit.prevent="submit" class="px-2">
		<v-row class="mt-0 mb-5">
			<!-- input data -->
			<v-col lg="9" md="8" cols="12" order-md="0" order="1" class="pb-md-0">
				<v-row class="mt-0 mb-2">
					<!-- name -->
					<v-col lg="6" cols="12">
						<v-text-field
							v-model="name"
							:label="$t('inputs.title')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="rules.required"
						></v-text-field>
					</v-col>

					<!-- summary -->
					<v-col lg="6" cols="12">
						<v-text-field
							v-model="summary"
							:label="$t('inputs.summary')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
						></v-text-field>
					</v-col>

					<!-- date-of-publication & is-featured -->
					<v-col lg="6" cols="12" class="d-flex flex-sm-row flex-column align-sm-center">
						<!-- date -->
						<v-menu
							ref="menu"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="date"
									:label="$t('inputs.date')"
									hide-details
									outlined
									dense
									class="rounded-medium"
									prepend-inner-icon="mdi-calendar"
									clearable
									:loading="loading"
									:disabled="loading || submitLoading"
									readonly
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="date"
								:max="(new Date().getFullYear() + 1) + new Date().toISOString().slice(4, 10)"
								min="1950-01-01"
								@change="save"
							></v-date-picker>
						</v-menu>

						<!-- is-featured -->
						<v-checkbox
							v-model="isFeatured"
							:label="$t('inputs.is-featured')"
							hide-details
							outlined
							class="mt-sm-0 ms-4 pt-0"
						></v-checkbox>
					</v-col>
				</v-row>
				
				<!-- SEO fields -->
				<template v-if="showSEO">
					<div class="text-body-1 font-weight-bold mb-3">
						{{$t('SEO-fields')}}
					</div>
					<v-row>
						<!-- SEO keywords -->
						<v-col cols="12">
							<v-text-field
								v-model="SEOkeywords"
								:label="$t('inputs.seo-keywords')"
								hide-details="auto"
								outlined
								dense
								class="rounded-medium"
								:loading="loading"
								:disabled="loading || submitLoading"
								hint="keywords should separated by (,) eg: keyword1, keyword2"
								@input="SEOkeywords = SEOkeywords.replace(/(\w) /g, '$1, ')"
							></v-text-field>
						</v-col>

						<!-- SEO description -->
						<v-col cols="12">
							<v-textarea
								v-model="SEOdescription"
								:label="$t('inputs.seo-description')"
								outlined
								dense
								auto-grow
								rows="1"
								no-resize
								counter
								:hint="SEOdescription && SEOdescription.length > 155 ? 'It\'s best to keep descriptions between 155-160 characters' : null"
								persistent-hint
								:color="SEOdescription && SEOdescription.length > 155 ? 'warning' : null"
								class="rounded-medium"
								:loading="loading"
								:disabled="loading || submitLoading"
								@keypress.enter.prevent
								@change="SEOdescription = SEOdescription.replace(/\n/g, ' ')"
							></v-textarea>
						</v-col>
					</v-row>
				</template>

				<!-- description -->
				<div class="text-body-1 font-weight-bold text-capitalize mb-3">{{$t('inputs.description')}}</div>
				<text-editor v-model="description"/>
			</v-col>

			<v-col cols="auto" class="d-md-block d-none px-1">
				<v-divider vertical/>
			</v-col>

			<!-- drop image -->
			<v-col md cols="12" class="pb-md-0">
				<div class="sticky">
					<div class="text-body-1 font-weight-bold mb-3">
						{{$t('upload-images')}}
					</div>
					<bee-drop-img
						v-model="mainImage"
						dashed
						border-width="2"
						:border-color="isSubmitted && !mainImage ? '#f00' : '#ccc'"
						radius="11"
						height="250px"
						blurable
						clearable
						:loading="loading"
						:disabled="loading || submitLoading"
						:hide-image-name="false"
					>
						<template v-slot:drag-text>
							<span class="text-body-1 font-weight-medium">
								{{$t('drag-text')}}
							</span>
							<span class="text-caption mb-0">
								{{$t('supported-text')}}
							</span>
						</template>
					</bee-drop-img>
					
					<bee-drop-zone
						v-if="0"
						v-model="images"
						dashed
						drop-zone-classes="pa-3"
						radius="11"
						border-width="2"
						max-height="190"
						:drop-image-props="{
							width: 82,
							height: 82
						}"
						:gap-size="7"
						:drop-menu-width="$vuetify.breakpoint.mdAndUp ? 400 : '100%'"
						:drop-menu-max-width="$vuetify.breakpoint.mdAndUp ? 400 : '100%'"
						:drop-menu-left="null"
						show-add-btn
						show-drop-menu
						no-drop-replace
						class="mt-3"
					>
						<template v-slot:drag-text>
							<p class="mb-0">
								<span class="d-block text-body-1 font-weight-bold">
									{{$t('upload-more-image')}}
								</span>
								<span class="d-block text-caption mb-0">{{$t('drag-text')}}</span>
							</p>
						</template>
					</bee-drop-zone>
				</div>
			</v-col>
		</v-row>
		<v-btn
			type="submit"
			color="primary"
			class="rounded-medium"
			:loading="submitLoading"
			:disabled="loading || submitLoading"
			@click="isSubmitted = true"
		>
			{{$t('save')}}
		</v-btn>
	</v-form>
</template>

<script>
import moment from 'moment'
import rules from '@/helpers/validation rules'
import { news, SEO } from '@/configs/routes.config';
export default {
	name: 'Product',
	
	data: () => ({
		// helpers
		loading: false,
		submitLoading: false,
		showSEO: SEO.show,
		isSubmitted: false,
		rules,
		
		// data
		name: null,
		summary: null,
		date: null,
		isFeatured: null,
		description: null,
		mainImage: null,
		images: [],
		SEOkeywords: '',
		SEOdescription: '',
	}),

	methods: {
		save (date) {
			this.$refs.menu.save(date)
		},

		submit() {
			if (this.$refs.form.validate() && this.mainImage) {
				this.submitLoading = true;
				const actionName = this.$route.params.id ? 'update' : 'create';
				this.$store.dispatch(`news/${actionName}`, {
					id: this.$route.params.id,
					title: this.name,
					summary: this.summary,
					date: this.date,
					isFeatured: this.isFeatured,
					content: this.description,
					image: typeof this.mainImage !== 'string' ? this.mainImage : null,
					keywords_seo: this.SEOkeywords,
					description_seo: this.SEOdescription,
				}).then((data) => {
					const message =
						actionName === 'update'
							? this.$t('$-is-updated-successfully', { name: this.$t('News.news') })
							: this.$t('$-is-created-successfully', { name: this.$t('News.news') });
					this.$eventBus.$emit('show-snackbar', false, message);

					this.$router.push({ name: news.name })
				}).finally(() => {
					this.submitLoading = false;
				})
			}
		}
	},

	created() {
		if (this.$route.params.id) {
			this.loading = true;
			this.$store.dispatch('news/fetchBySlug', {
				slug: this.$route.params.slug
			}).then((res) => {
				const data = res;
				this.name = data.Title;
				this.summary = data.summary;
				this.date = data.Date ? moment(data.Date).format('YYYY-MM-DD') : null;
				this.isFeatured = data.is_featured;
				this.description = data.Content;
				this.mainImage = data.primary_image.url;
				this.SEOkeywords = data.keywords_seo ? data.keywords_seo : '';
				this.SEOdescription = data.description_seo ? data.description_seo : '';
			}).finally(() => {
				this.loading = false;
			})
		}
	}
};
</script>

<style lang="scss">
</style>