import * as routesTitles from '@/configs/translates.config';

export default {
	pages: {
		dashboard: routesTitles.dashboard.en.plural,
		sets: routesTitles.sets.en.plural,
		categories: routesTitles.categories.en.plural,
		news: routesTitles.news.en.plural,
		filters: routesTitles.filters.en.plural,
		keywords: routesTitles.keywords.en.plural,
		products: routesTitles.products.en.plural,
		orders: routesTitles.orders.en.plural,
		plans: routesTitles.plans.en.plural,
		albums: routesTitles.albums.en.plural,
		users: routesTitles.users.en.plural,
		sliders: routesTitles.sliders.en.plural,
		subscriptions: routesTitles.subscriptions.en.plural,
		'about-us': routesTitles.aboutUs.en.plural,
		seo: routesTitles.SEO.en.plural,
	}
}