import moment from 'moment';
import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		authenticated: false,
		self: {
			name: null,
			expiresIn: null,
		}
	},

	actions: {
		login({ commit }, { username, password }) {
			return axios.post('/auth/login', { username, password }).then((response) => {
				const auth = {
					token: response.data.access_token,
					name: response.data.fullname,
					expiresIn: response.data.expires_in
				};
				window.axios.defaults.headers.common.authorization = `Bearer ${auth.token}`;
				localStorage.setItem('auth', JSON.stringify(auth));
				localStorage.setItem('expiry-date', JSON.stringify(moment().add(auth.expiresIn, 'seconds').toISOString()));
				commit(types.LOGIN, auth);
			})
		},

		logout({ commit }) {
			delete window.axios.defaults.headers.common.authorization;
			localStorage.removeItem('auth');
			localStorage.removeItem('expiry-date');
			commit(types.LOGUT);
		}
	},

	mutations: {
		[types.LOGIN](state, auth) {
			state.authenticated = true;
			state.self.name = auth.name;
			state.self.expiresIn = auth.expiresIn;
		},
		
		[types.LOGUT](state) {
			state.authenticated = false;
			state.self = {};
		}
	}
}