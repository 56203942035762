export const LOGIN = 'LOGIN'; 
export const LOGUT = 'LOGUT';

export const STORE_STATICS = 'STORE_STATICS';

export const STORE_USERS = 'STORE_USERS';

export const STORE_SETS = 'STORE_SETS';

export const STORE_CATEGORIES = 'STORE_CATEGORIES';

export const STORE_FILTERS = 'STORE_FILTERS';

export const STORE_KEYWORDS = 'STORE_KEYWORDS';

export const STORE_PRODUCTS = 'STORE_PRODUCTS';

export const STORE_NEWS = 'STORE_NEWS';

export const STORE_ORDERS = 'STORE_ORDERS';

export const STORE_PLANS = 'STORE_PLANS';

export const STORE_ALBUMS = 'STORE_ALBUMS';

export const STORE_SLIDERS = 'STORE_SLIDERS';

export const STORE_SUBSCRIPTIONS = 'STORE_SUBSCRIPTIONS';

export const STORE_ABOUT_US = 'STORE_ABOUT_US';

export const STORE_SEO = 'STORE_SEO';

export const STORE_FILE_MANGER_IMAGE = 'STORE_FILE_MANGER_IMAGE';