export default {
	all: 'All',
	status: {
		approved: 'Approved',
		'in-preparation': 'In preparation',
		shipping: 'Shipping',
		delivered: 'Delivered',
		paid: 'Paid',
		unpaid: 'Unpaid',
		canceled: 'Canceled'
	},
	'order-status': 'Order status'
}