<template>
	<div>
		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="plans"
			:ids="deleteItemId"
			@on-delete="fetchPlans"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Plans.the-plan')}}
		</bee-delete-dialog>

		<!-- confirm dialog -->
		<v-dialog
			v-model="confirmDialog"
			width="400"
			:persistent="changeStateLoading"
		>
			<v-card class="rounded-medium">
				<v-card-title class="justify-center text-uppercase text-h6">
					{{ $t('Plans.confirm') + ' ' + titleState }}
				</v-card-title>
				<v-card-text class="text-body-1 pb-2">
					{{$t('Plans.are-you-sure-you-want-to-$-this-user', { state: titleState })}}
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn
						color="primary"
						class="rounded-small"
						:loading="changeStateLoading"
						:disabled="changeStateLoading"
						@click="changeUserState()"
					>
						{{titleState}}
					</v-btn>
					<v-btn
						class="rounded-small"
						:disabled="changeStateLoading"
						@click="confirmDialog = false; confirmId = null"
					>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		
		<!-- plan users -->
		<v-dialog v-model="planUsersDialog" width="700">
			<v-card class="rounded-medium">
				<v-card-title class="justify-center text-uppercase">
					{{$t('Plans.plan-users')}}
				</v-card-title>
				<v-card-text class="pt-1 pb-2">
					<v-data-table
						:items="planUsers"
						:headers="planUsersHeaders"
						dense
						class="plans-users-table elevation-3 rounded-medium"
					></v-data-table>
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn
						color="primary"
						class="rounded-medium"
						@click="planUsersDialog = false; planId = null;"
					>{{$t('close')}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-3">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							:to="{ name: 'plan' }"
						>
							<v-icon>mdi-plus</v-icon>
							{{$t('Plans.add-new-plan')}}
						</v-btn>
					</v-col>
					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : filteredPlans"
			:loading="loading || orderLoading"
			:items-per-page="15"
			:item-class="item => dragIndex === plans.indexOf(item) ? 'drag-item' : null"
			class="elevation-3 rounded-medium"
		>
			<!-- offer price -->
			<template v-slot:item.offerPrice="{ item }">
				{{item.offerPrice || '-'}}
			</template>
			
			<!-- offer start date -->
			<template v-slot:item.offerStart="{ item }">
				{{item.offerStart ? moment(item.offerStart).format('YYYY-MM-DD') : '-'}}
			</template>

			<!-- offer end date -->
			<template v-slot:item.offerEnd="{ item }">
				{{item.offerEnd ? moment(item.offerEnd).format('YYYY-MM-DD') : '-'}}
			</template>
			
			<!-- isFeatured -->
			<template v-slot:item.isFeatured="{ item }">
				<v-icon v-if="item.isFeatured">mdi-check-circle-outline</v-icon>
				<span v-else>-</span>
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<div class="d-flex justify-center align-center">
					<v-btn
						color="deep-purple darken-2 white--text"
						dark
						small
						class="ms-1 px-1 flex-shrink-1"
						min-width="20px"
						@click="planId = item.id; planUsersDialog = true"
					><v-icon>mdi-account-group</v-icon></v-btn>

					<v-btn
						color="blue darken-4"
						dark
						small
						class="ms-1 px-1 flex-shrink-1"
						min-width="20px"
						:to="{ name: 'plan', params: { slug: item.slug, id: item.id } }"
					><v-icon>mdi-pencil</v-icon></v-btn>
					<v-btn
						color="red darken-1"
						dark
						small
						class="ms-1 px-1"
						min-width="20px"
						@click="deleteItemId = item.id; deleteDialog = true"
					>
						<v-icon>mdi-delete</v-icon>
					</v-btn>
					<v-switch
						v-model="item.isActive"
						:ripple="false"
						color="darkGreen"
						hide-details
						class="ms-2 mt-0 pt-0"
						readonly
						@click="activeConfirmDialog(item)"
					></v-switch>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import { plans } from '@/configs/translates.config'
import rules from '@/helpers/validation rules'

export default {
	name: 'Plans',

	data: () => ({
		deleteDialog: false,
		deleteItemId: null,

		restoreDialog: false,
		restoreItemId: null,

		confirmDialog: false,
		changeStateLoading: false,
		confirmId: null,

		orderDialog: false,
		changeOrderLoading: false,
		orderLoading: false,
		orderItemId: null,
		orderItem: null,

		planUsersDialog: false,
		planId: null,

		// filter
		search: null,
		isFiltered: false,

		// helpers
		titleState: null,
		loading: false,
		dragIndex: null,

		rules,
	}),

	watch: {
		orderDialog(val) {
			if (!val) {
				this.$refs.orderFrom.reset();
				this.orderItemId = null;
			}
		}
	},

	computed: {
		...mapState({
			plans: state => state.plans.plans
		}),
		...mapGetters({
			getPlanById: 'plans/getPlanById'
		}),

		headers() {
			const headers = [
				{ text: this.$t('headers.name'), value: 'name', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.duration'), value: 'duration', align: 'center' },
				{ text: this.$t('headers.price'), value: 'price', align: 'center' },
				/* { text: this.$t('headers.offer-price'), value: 'offerPrice', align: 'center' },
				{ text: this.$t('headers.offer-start-date'), value: 'offerStart', align: 'center' },
				{ text: this.$t('headers.offer-end-date'), value: 'offerEnd', align: 'center' }, */
				{ text: this.$t('headers.featured'), value: 'isFeatured', align: 'center' },
				{ text: this.$t('headers.actions'), value: 'actions', align: 'center', width: 250, sortable: false },
			];

			for (let i = 0; i < headers.length; i++) {
				headers[i].class = headers[i].class ? headers[i].class + ' px-2' : 'px-2';
				headers[i].cellClass = headers[i].cellClass ? headers[i].cellClass + ' px-2' : 'px-2';
			}

			return headers
		},

		planUsersHeaders() {
			return [
				{ text: this.$t('headers.fullname'), value: 'fullname', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.username'), value: 'username', align: 'center' },
				{ text: this.$t('headers.country'), value: 'country', align: 'center' },
			]
		},

		planUsers() {
			if (this.planId) {
				const users = this.getPlanById(this.planId).users;
				return users
			} else {
				return [];
			}
		},

		filteredPlans() {
			return this.filterByName(this.isFiltered, this.plans, this.search)
		},
	},

	methods: {
		fetchPlans() {
			this.loading = true;
			this.$store.dispatch('plans/fetchAll').finally(() => {
				this.loading = false;
			})
		},

		activeConfirmDialog(item) {
			this.confirmId = item.id;
			this.titleState = item.isActive ? this.$t('Plans.deactivate') : this.$t('Plans.activate');
			this.confirmDialog = true;
		},

		changeUserState() {
			this.changeStateLoading = true;
			this.$store.dispatch('plans/changeState', {
				id: this.confirmId
			}).then(() => {
				this.confirmDialog = false
				this.$eventBus.$emit('show-snackbar', false, this.$t('$-is-updated-successfully', { name: plans.en.singular }));
				this.fetchPlans();
			}).finally(() => {
				this.changeStateLoading = false;
			})
		},

		changeOrder(useOrderLoading, order) {
			if (useOrderLoading) {
				this.orderLoading = true;
			} else {
				this.changeOrderLoading = true;
			}
			this.$store.dispatch('plans/changeOrder', {
				id: this.orderItemId,
				order,
			}).then(() => {
				this.orderDialog = false;
				this.$eventBus.$emit('show-snackbar', false, this.$t('messages.item-order-is-updated'));
				if (useOrderLoading) {
					this.$store.dispatch('plans/fetchAll');
				} else {
					this.fetchPlans();
				}
			}).finally(() => {
				this.orderLoading = false;
				this.changeOrderLoading = false;
			})
		},

		// helpers
		moment,
	},

	created() {
		this.fetchPlans();
	}
}
</script>

<style lang="scss">
.plans-users-table .v-data-footer__select {
	height: 36px;
}
</style>