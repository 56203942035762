export const login = {
	en: {
		singular: 'login',
		plural: 'login'
	},
	ar: ''
}

export const preview = {
	en: {
		singular: 'preview',
		plural: 'preview'
	},
	ar: ''
}

export const dashboard = {
	en: {
		singular: 'dashboard',
		plural: 'dashboard'
	},
	ar: ''
}

export const sets = {
	en: {
		singular: 'tab',
		plural: 'tabs'
	},
	ar: ''
}

export const categories = {
	en: {
		singular: 'category',
		plural: 'categories'
	},
	ar: ''
}

export const category = {
	en: {
		singular: 'category',
		plural: 'categories'
	},
	ar: ''
}
export const filters = {
	en: {
		singular: 'filter',
		plural: 'filters'
	},
	ar: ''
}
export const keywords = {
	en: {
		singular: 'tag',
		plural: 'tags'
	},
	ar: ''
}

export const products = {
	en: {
		singular: 'product',
		plural: 'products'
	},
	ar: ''
}

export const product = {
	en: {
		singular: 'product',
		plural: 'products'
	},
	ar: ''
}
export const news = {
	en: {
		singular: 'blog',
		plural: 'blogs'
	},
	ar: ''
}

export const newsIndex = {
	en: {
		singular: 'blog',
		plural: 'blogs'
	},
	ar: ''
}

export const orders = {
	en: {
		singular: 'order',
		plural: 'orders'
	},
	ar: ''
}

export const order = {
	en: {
		singular: 'order',
		plural: 'order'
	},
	ar: ''
}

export const plans = {
	en: {
		singular: 'plan',
		plural: 'plans'
	},
	ar: ''
}

export const plan = {
	en: {
		singular: 'plan',
		plural: 'plan'
	},
	ar: ''
}

export const albums = {
	en: {
		singular: 'video',
		plural: 'videos'
	},
	ar: ''
}

export const sliders = {
	en: {
		singular: 'slider',
		plural: 'sliders'
	},
	ar: ''
}

export const users = {
	en: {
		singular: 'user',
		plural: 'users'
	},
	ar: ''
}

export const userInfo = {
	en: {
		singular: 'user info',
		plural: 'users info'
	},
	ar: ''
}

export const subscriptions = {
	en: {
		singular: 'subscription',
		plural: 'subscriptions'
	},
	ar: ''
}

export const aboutUs = {
	en: {
		singular: 'about us',
		plural: 'about us',
	},
	ar: ''
}

export const SEO = {
	en: {
		singular: 'SEO',
		plural: 'SEO',
	},
	ar: ''
}