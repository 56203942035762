import * as types from '../mutation-types'
export default {
	namespaced: true,

	state: {
		orders: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/orders')
				.then((response) => {
					commit(types.STORE_ORDERS, response.data)
					return response.data;
				})
		},
		
		fetchById({ commit }, { id }) {
			return axios.get(`/orders/view/${id}`)
				.then((response) => {
					return response.data;
				})
		},
	},

	mutations: {
		[types.STORE_ORDERS](state, orders) {
			state.orders = orders;
		}
	},

	getters: {
		getOrderById: state => id => state.orders.find(c => c.id === id)
	}
}