<template>
	<v-app>
		<navbar v-if="!['login'].includes($route.name)" @change-drawer="drawer = !drawer"></navbar>
		<navigation-drawer v-if="!['login'].includes($route.name)" v-model="drawer"></navigation-drawer>
		<v-main class="bgColor">
			<v-container fluid class="py-3 px-4">
				<router-view/>
			</v-container>
		</v-main>

        <v-snackbar
			id="app-snackbar"
            v-model="snackbar"
            :color="isError ? 'red lighten-1' : 'primary lighten-1'"
			content-class="rounded-medium"
			style="z-index: 1400"
        >
            {{ message }}
            <template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					icon
					v-bind="attrs"
					@click="snackbar = false"
				>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
	</v-app>
</template>

<script>
import Navbar from './components/Navbar.vue'
import NavigationDrawer from './components/NavigationDrawer.vue'
import { brandName } from './configs/brand.cofig'
export default {
	name: 'App',
	components: { Navbar, NavigationDrawer },

	data: () => ({
		drawer: false,
		snackbar: false,
		isError: false,
		message: null
	}),

	created() {
		this.$eventBus.$on('show-snackbar', (isError, message) => {
			this.isError = isError;
			this.message = message;
			this.snackbar = true;
		})
	},

	metaInfo() {
		const title = this.$route.name;
		return {
			title: title.charAt(0).toUpperCase() + title.slice(1).replace(/-/g, ' '),
			titleTemplate: `%s | ${brandName}`
		}
	}
}
</script>

<style lang="scss">
@import './scss/main.scss';
*{
	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		background-color: #f1f1f1;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #bbb;
		border-radius: 11px;
	}
}

.rounded {
	&-large {
		border-radius: 15px !important;
	}
	&-medium {
		border-radius: 11px !important;
	}
	&-small {
		border-radius: 9px !important;
	}
}
.number{
	&-large {
		font-size: 2.4rem !important;
		font-weight: 500;
	}
}
.sticky {
	position: sticky;
	top: 65px;
}

.ellipsis {
	text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 120px;
}

.v-snack__wrapper {
	border-radius: 11px !important;
}

body .tox {
	.tox-dialog {
		border-radius: 11px;
	}
	.tox-button:not(.tox-button--secondary, .tox-button--naked) {
		background-color: #084566;
		border-color: #084566;
	}
}
#app {
	.tox .tox-editor-header {
		z-index: 0;
	}
	.tox-tinymce {
		border-radius: 11px;
	}

	.v-counter {
		font-weight: 500;
		font-size: .9rem;
	}

	.powered-by {
		text-align: center;
		* {
			font-size: 11px;
		}
		a {
			color: inherit;
			text-decoration: none;
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: 1px;
		}
	}
	#navigation-drawer.theme--dark .powered-by {
		color: white
	}
}
</style>