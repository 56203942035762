<template>
	<div>
		<!-- description -->
		<div class="text-body-1 font-weight-bold text-capitalize mb-3">{{$t('Order.user-info')}}</div>
		<v-row>
			<!-- name -->
			<v-col lg="2" md="4" sm="6" cols="12">
				<v-text-field
					:label="$t('Order.name')"
					outlined
					hide-details
					persistent-placeholder
					readonly
					dense
					class="rounded-medium"
					prepend-icon="mdi-account"
					:value="name"
					:loading="loading"
				/>
			</v-col>
			
			<!-- address -->
			<v-col lg="3" md="4" sm="6" cols="12">
				<v-text-field
					:label="$t('Order.address')"
					outlined
					hide-details
					persistent-placeholder
					readonly
					dense
					class="rounded-medium"
					prepend-icon="mdi-map-marker-outline"
					:value="address"
					:loading="loading"
				/>
			</v-col>
			
			<!-- phone -->
			<v-col lg="2" md="4" sm="6" cols="12">
				<v-text-field
					:label="$t('Order.phone')"
					outlined
					hide-details
					persistent-placeholder
					readonly
					dense
					class="rounded-medium"
					prepend-icon="mdi-phone"
					:value="phone"
					:loading="loading"
				/>
			</v-col>
			
			<!-- email -->
			<v-col lg="3" md="4" sm="6" cols="12">
				<v-text-field
					:label="$t('Order.email')"
					outlined
					hide-details
					persistent-placeholder
					readonly
					dense
					class="rounded-medium"
					prepend-icon="mdi-email-outline"
					:value="email"
					:loading="loading"
				/>
			</v-col>

			<!-- date -->
			<v-col lg="2" md="4" sm="6" cols="12">
				<v-text-field
					:label="$t('Order.date')"
					outlined
					hide-details
					persistent-placeholder
					readonly
					dense
					class="rounded-medium"
					prepend-icon="mdi-calendar"
					:value="date ? moment(date).format('DD-MM-YYYY') : null"
					:loading="loading"
				/>
			</v-col>
		</v-row>

		<div class="text-body-1 font-weight-bold text-capitalize mt-6 mb-3">{{$t('Order.payment-info')}}</div>
		<v-row>
			<!-- total -->
			<v-col lg="2" md="4" sm="6" cols="12">
				<v-text-field
					:label="$t('Order.total')"
					outlined
					hide-details
					persistent-placeholder
					readonly
					dense
					class="rounded-medium"
					prepend-icon="mdi-cash"
					:value="total"
					:loading="loading"
				/>
			</v-col>
			
			<!-- payment type -->
			<v-col lg="2" md="4" sm="6" cols="12">
				<v-text-field
					:label="$t('Order.payment-type')"
					outlined
					hide-details
					persistent-placeholder
					readonly
					dense
					class="rounded-medium"
					prepend-icon="mdi-credit-card-outline"
					:value="paymentType"
					:loading="loading"
				/>
			</v-col>
		</v-row>

		<!-- cart -->
		<div class="text-body-1 font-weight-bold text-capitalize mt-6 mb-3">{{$t('Order.cart')}}</div>
		<v-data-table
			:headers="headers"
			:items="loading ? [] : products"
			:loading="loading"
			:items-per-page="5"
			class="elevation-2 rounded-medium transparent"
		>
			<!-- image -->
			<template v-slot:item.image="{ item }">
				<v-img
					:src="item.primary_image.url"
					:height="45"
					:width="45"
					lazy-src="@/assets/placeholder-image.png"
					class="rounded-medium mx-auto"
				/>
			</template>

			<!-- categories -->
			<template v-slot:item.categories_ids="{ item }">
				{{getCategories(item) | isAvailable}}
			</template>
		</v-data-table>
	</div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapState } from 'vuex';
export default {
	name: 'Order',

	data: () => ({
		name: null,
		address: null,
		phone: null,
		email: null,
		date: null,
		total: null,
		paymentType: null,
		products: [],

		loading: false,
	}),

	computed: {
		...mapState({
			categories: state => state.categories.categories,
		}),

		...mapGetters({
			getCategoryById: 'categories/getCategoryById',
		}),
		
		headers() {
			return [
				{ text: this.$t('headers.image'), value: 'image', align: 'center', sortable: false },
				{ text: this.$t('headers.name'), value: 'name', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.category'), value: 'categories_ids', align: 'center', sortable: false },
				{ text: this.$t('headers.quantity'), value: 'quantity', align: 'center' },
				{ text: this.$t('headers.price'), value: 'price', align: 'center' },
				{ text: this.$t('headers.total'), value: 'total', align: 'center' },
			]
		}
	},

	methods: {
		getCategories(item) {
			if (item.categories_ids && item.categories_ids.length) {
				const names = [];
				item.categories_ids.forEach(id => {
					const category = this.getCategoryById(id).name;
					names.push(category)
				});

				return names.join(', ')
			}
		},

		moment,
	},

	created() {
		this.loading = true;
		Promise.all([
			this.categories.length === 0 ? this.$store.dispatch('categories/fetchAll') : null,
			this.$store.dispatch('orders/fetchById', { id: this.$route.params.id })
				.then((data) => {
					this.name = data.fullname;
					this.address = data.address;
					this.phone = data.phone;
					this.email = data.email;
					this.date = data.created_at;
					this.total = data.total;
					this.paymentType = data.payment_type;
				
					this.products = data.cart.products.map(c => {
						const product = c.product;
						product.quantity = c.quantity;
						product.total = c.total;
						return product
					});
				})

		]).finally(() => {
			this.loading = false;
		})
	}
}
</script>

<style>

</style>