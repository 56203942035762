<template>
	<div>
		<!-- delete dialog -->
		<bee-delete-dialog
			v-if="false"
			v-model="deleteDialog"
			moduleType=''
			:ids="deleteItemId"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Subscriptions.the-subscripter')}}
		</bee-delete-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-3">
				<v-row class="justify-center align-center">
					<v-col md="4" sm="6" cols="12">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium"
						></v-text-field>
					</v-col>
					<v-spacer/>
					<v-col cols="auto">
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		
		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : filteredSubscriptions"
			:loading="loading"
			:items-per-page="15"
			class="elevation-3 rounded-medium"
		>
			<template v-if="0" v-slot:item.actions="{ item }">
				<v-btn
					color="red darken-1"
					dark
					small
					class="px-1"
					min-width="20px"
					@click="deleteDialog = true; deleteItemId = item.id"
				>
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Subscriptions',

	data: () => ({
		deleteDialog: false,
		deleteItemId: null,

		// filtered
		search: null,
		isFiltered: false,

		// helpers
		loading: null,
	}),

	computed: {
		...mapState({
			subscriptions: state => state.subscriptions.subscriptions,
		}),
		
		headers() {
			return [
				{ text: this.$t('headers.name'), value: 'fullname', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.email'), value: 'mail', align: 'center' },
				// { text: this.$t('headers.phone'), value: 'phone', align: 'center' },
			]
		},
		
		filteredSubscriptions() {
			return this.filterByName(this.isFiltered, this.subscriptions, this.search);
		}
	},

	methods: {
		fetchSubscriptions() {
			this.loading = true;
			this.$store.dispatch('subscriptions/fetchAll')
				.finally(() => {
					this.loading = false;
				})
		}
	},

	created() {
		this.fetchSubscriptions();
	}
}
</script>

<style>

</style>