<template>
	<div id="orders">
		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-3">
				<v-row class="align-center">
					<!-- status filter -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-row align="center">
							<v-col lg="auto" md="8" cols="12" v-if="false">
								<v-chip-group
									v-model="orderStatusAction"
									mandatory
									show-arrows
									center-active
								>
									<v-chip
										v-for="(item, index) in orderStatusActions"
										:key="index"
										:value="item.value"
										:active-class="`${getStatusColor(item.value)} white--text`"
										class="rounded-medium"
									>
										{{item.title}}
									</v-chip>
								</v-chip-group>
							</v-col>
							<v-spacer v-if="false"/>
							<v-col lg="3" md="4" cols="12">
								<v-autocomplete
									v-model="status"
									:label="$t('Orders.order-status')"
									:items="orderStatus"
									item-text="title"
									item-value="value"
									outlined
									dense
									hide-details
									clearable
									class="rounded-small"
								></v-autocomplete>
							</v-col>
							<v-col md="5" cols="12" class="d-flex align-center" v-if="false">
								<v-text-field
									v-model="search"
									:label="$t('search')"
									hide-details
									outlined
									dense
									prepend-inner-icon="mdi-magnify"
									class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
								></v-text-field>
							</v-col>
							<v-spacer/>
						</v-row>
					</v-col>
					
					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							v-if="true"
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-spacer v-if="false"/>
						
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="loading ? [] : filteredOrders"
			:loading="loading"
			:items-per-page="15"
			class="elevation-3 rounded-medium"
		>
			<!-- createdAt -->
			<template v-slot:item.createdAt="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<span
							v-bind="attrs"
							v-on="on"
						>
							{{moment(item.createdAt).format('DD-MM-YYYY')}}
						</span>
					</template>
					
					<span>
						{{moment(item.createdAt).format('dddd MMM YYYY, hh:mm a')}}
					</span>
				</v-tooltip>
			</template>

			<!-- status -->
			<template v-slot:item.status="{ item }">
				<v-btn
					text
					dark
					small
					:color="item.status ? 'success' : 'red lighten-1'"
				>{{getStatusText(item.status)}}</v-btn>
			</template>

			<!-- current status -->
			<template v-slot:item.currentStatus="{item}">
				<div class="d-flex align-center">
					<v-icon class="me-2" :color="getStatusColor(item.currentStatus)">mdi-circle-medium</v-icon>
					<v-select
						v-model="item.currentStatus"
						:items="statuses"
						item-text="title"
						item-value="value"
						dense
						hide-details
						class="rounded-medium"
						append-icon="mdi-chevron-down"
					></v-select>
				</div>
			</template>

			<!-- acitons -->
			<template v-slot:item.actions="{ item }">
				<div class="d-flex justify-center">
					<v-btn
						color="deep-purple darken-2"
						dark
						small
						class="me-1 px-1 flex-shrink-1"
						min-width="20px"
						:to="{ name: 'order', params: { id: item.id } }"
					><v-icon>mdi-eye</v-icon></v-btn>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
export default {
	name: 'Orders',
	data: () => ({
		// filter
		orderStatusAction: null,
		status: null,
		search: null,
		isFiltered: false,

		// helpers
		loading: false,
	}),

	computed: {
		...mapState({
			orders: state => state.orders.orders	
		}),

		orderStatusActions() {
			return [
				{ title: this.$t('Orders.all'), value: -1 },
				{ title: this.$t('Orders.status.approved'), value: 0 },
				{ title: this.$t('Orders.status.in-preparation'), value: 1 },
				{ title: this.$t('Orders.status.shipping'), value: 2 },
				{ title: this.$t('Orders.status.delivered'), value: 3 },
				{ title: this.$t('Orders.status.canceled'), value: 4 },
			]
		},
		orderStatus() {
			return [
				{ title: this.$t('Orders.status.paid'), value: 1 },
				{ title: this.$t('Orders.status.unpaid'), value: 0 }
			]
		},

		headers() {
			return [
				{ text: this.$t('headers.user'), value: 'fullname', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.created-at'), value: 'createdAt', align: 'center', sortable: false },
				{ text: this.$t('headers.total'), value: 'total', align: 'center' },
				{ text: this.$t('headers.status'), value: 'status', align: 'center' },
				{ text: this.$t('headers.payment-type'), value: 'payment_type', align: 'center' },
				// { text: this.$t('headers.current-status'), value: 'currentStatus', align: 'center', width: 200 },
				{ text: this.$t('headers.actions'), value: 'actions', align: 'center', sortable: false },
			]
		},

		statuses() {
			return [
				{ title: this.$t('Orders.status.approved'), value: 0 },
				{ title: this.$t('Orders.status.in-preparation'), value: 1 },
				{ title: this.$t('Orders.status.shipping'), value: 2 },
				{ title: this.$t('Orders.status.delivered'), value: 3 },
				{ title: this.$t('Orders.status.canceled'), value: 4 },
			]
		},

		filteredOrders() {
			return this.filterByName(this.isFiltered, this.orders, this.search || '', 'fullname')
				.filter(c => this.isFiltered && this.status !== null ? c.status === this.status : true)
		}
	},

	methods: {
		getStatusText(state) {
			switch (state) {
			case 0 : return this.$t('Orders.status.unpaid');
			case 1 : return this.$t('Orders.status.paid');
			}
		},
		getStatusColor(state) {
			switch (state) {
			case 0 : return 'light-blue darken-1';
			case 1 : return 'yellow darken-1';
			case 2 : return 'indigo darken-4';
			case 3 : return 'success';
			case 4 : return 'red lighten-1';
			default : return 'primary'
			}
		},

		// helpers
		moment
	},

	created() {
		this.loading = true;
		this.$store.dispatch('orders/fetchAll')
			.finally(() => {
				this.loading = false;
			})
	}
}
</script>

<style lang="scss">
#orders {
	.v-text-field > .v-input__control > .v-input__slot:before,
	.v-text-field > .v-input__control > .v-input__slot:after {
		display: none;
	}
}
</style>