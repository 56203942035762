<template>
	<div>
		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="categories"
			:ids="deleteItemId"
			@on-delete="fetchCategories"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Categories.the-category')}}
		</bee-delete-dialog>

		<!-- add/edit dialog -->
		<v-dialog
			v-model="dialog"
			width="400"
			:persistent="dialogLoading"
		>
			<v-card class="rounded-medium">
				<v-form ref="form" @submit.prevent="submit">
					<v-card-title class="justify-center text-uppercase text-h6 mb-3">
						{{ editItemId ? $t('edit') : $t('add')}} {{$t('Categories.category')}}
					</v-card-title>
					<v-card-text class="pb-2">
						<v-row class="flex-column mt-0">
							<v-col class="py-0">
								<v-text-field
									v-model="name"
									:label="$t('inputs.name')"
									outlined
									dense
									class="rounded-small"
									:rules="rules.required"
								></v-text-field>
							</v-col>
							<v-col class="py-0">
								<v-autocomplete
									v-model.number="setIds"
									:items="sets"
									item-text="name"
									item-value="id"
									:label="$t('inputs.set')"
									outlined
									dense
									class="rounded-small"
									:rules="rules.requiredAsNumber"
								></v-autocomplete>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							type="submit"
							color="primary"
							class="rounded-small"
							:loading="dialogLoading"
							:disabled="dialogLoading"
						>
							{{$t('save')}}
						</v-btn>
						<v-btn
							@click="dialog = false"
							class="rounded-small"
							:disabled="dialogLoading"
						>
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-3">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="6" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="dialog = true"
						>
							<v-icon>mdi-plus</v-icon>
							{{$t('Categories.add-new-category')}}
						</v-btn>
					</v-col>
					<v-spacer/>
					<!-- sort & search -->
					<v-col lg="4" md="6" cols="12" class="text-md-start text-center">
						<v-row class="align-center">
							<v-col sm="4" cols="12" v-if="false">
								<v-select
									:label="$t('sort-by')"
									hide-details
									:items="['Id', 'name']"
									outlined
									dense
									:menu-props="{ offsetY: true }"
									class="rounded-medium"
								></v-select>
							</v-col>
							<v-col sm cols="12">
								<v-text-field
									v-model="search"
									:label="$t('search')"
									hide-details
									outlined
									dense
									prepend-inner-icon="mdi-magnify"
									class="rounded-medium"
								></v-text-field>
							</v-col>
							<v-col sm="auto" cols="12">
								<v-btn
									:color="isFiltered ? null : 'primary'"
									class="rounded-small"
									@click="isFiltered =! isFiltered"
								>
									{{isFiltered ? $t('cancel') : $t('search')}}
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- tree -->
		<v-card v-if="false" class="rounded-medium">
			<v-card-text>
				<v-treeview
					dense
					:items="items"
					:load-children="null"
					transition
					activatable
					open-all
					expand-icon="mdi-chevron-down"
					active-class
				>
					<template v-slot:label="{ item }">
						<v-row
							v-ripple
							class="flex-md-row flex-column align-center tree-row ma-0"
						>
							<!-- name -->
							<v-col lg="3" class="py-2">
								<span class="text-body-2 me-1">{{$t('headers.name')}}:</span>
								<span class="text-body-1 font-weight-medium primary--text">{{item.name}}</span>
							</v-col>

							<!-- number of products -->
							<v-col lg="2" class="py-2">
								<span class="text-body-2 me-1">{{$t('headers.number-of-products')}}:</span>
								<span class="text-body-1 font-weight-medium">{{item.numberOfProducts}}</span>
							</v-col>

							<!-- summary -->
							<v-col lg="5" class="py-2">
								<template v-if="item.summary">
									<span class="text-body-2 me-1">{{$t('headers.summary')}}:</span>
									<p
										class="text-subtitle-2 main--text font-weight-medium mb-0 tree-row__summary"
									>
										{{item.summary}}
									</p>
								</template>
							</v-col>

							<!-- acitons -->
							<v-col lg="2" class="text-md-end text-center py-2">
								<v-btn
									color="blue darken-4"
									dark
									small
									class="me-1 px-1 flex-shrink-1"
									min-width="20px"
									@click="editItemId = id; dialog = true;"
								><v-icon>mdi-pencil</v-icon></v-btn>
								<v-btn
									color="red darken-1"
									dark
									small
									class="px-1"
									min-width="20px"
									@click="deleteItemId = item.id; deleteDialog = true;"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</v-col>
						</v-row>
						<v-divider/>
					</template>
				</v-treeview>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			v-else
			:headers="headers"
			:items="loading ? [] : filterdCategories"
			:loading="loading"
			:items-per-page="15"
			class="elevation-3 rounded-medium"
		>
			<!-- sets -->
			<template v-slot:item.sets_ids="{ item }">
				{{getSets(item) | isAvailable}}
			</template>

			<!-- action -->
			<template v-slot:item.actions="{ item }">
				<v-btn
					color="blue darken-4"
					dark
					small
					class="me-1 px-1 flex-shrink-1"
					min-width="20px"
					@click="dialog = true; editItemId = item.id"
				><v-icon>mdi-pencil</v-icon></v-btn>
				<v-btn
					color="red darken-1"
					dark
					small
					class="px-1"
					min-width="20px"
					@click="deleteDialog = true; deleteItemId = item.id"
				>
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapGetters, mapState } from 'vuex';
export default {
	name: 'Categories',

	data: () => ({
		dialog: false,
		editItemId: null,
		deleteDialog: false,
		deleteItemId: null,

		// filter
		search: null,
		isFiltered: false,

		// helpers
		loading: false,
		dialogLoading: false,
		rules,

		items: [
			{
				id: '1',
				name: 'Applications',
				numberOfProducts: 1,
				summary: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem ducimus tenetur rem molestiae totam explicabo ipsum deleniti.',
				children: [
					{ id: '2', name: 'Calendar : app', numberOfProducts: 2 },
					{ id: '3', name: 'Chrome : app', numberOfProducts: 3 },
					{ id: '4', name: 'Webstorm : app', numberOfProducts: 4 },
				],
			},
			{
				id: '5',
				name: 'Documents',
				numberOfProducts: 5,
				summary: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem ducimus tenetur rem molestiae totam explicabo ipsum deleniti.',
				children: [
					{
						id: '6',
						name: 'vuetify',
						numberOfProducts: 6,
						children: [
							{
								id: '7',
								name: 'src',
								numberOfProducts: 21,
								children: [
									{ id: '8', name: 'index : ts', numberOfProducts: 8 },
									{ id: '9', name: 'bootstrap : ts', numberOfProducts: 9 },
								],
							},
						],
					},
					{
						id: '10',
						name: 'material2',
						numberOfProducts: 21,
						summary: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem ducimus tenetur rem molestiae totam explicabo ipsum deleniti.',
						children: [
							{
								id: '11',
								name: 'src',
								numberOfProducts: 21,
								children: [
									{ id: '12', name: 'v-btn : ts', numberOfProducts: 12 },
									{ id: '13', name: 'v-card : ts', numberOfProducts: 13 },
									{ id: '14', name: 'v-window : ts', numberOfProducts: 14 },
								],
							},
						],
					},
				],
			}
		],

		// data
		name: null,
		setIds: null,
	}),

	watch: {
		dialog(val) {
			if (val) {
				if (this.editItemId) {
					const item = this.getCategoryById(this.editItemId);
					this.name = item.name;
					this.setIds = item.sets_ids[0];
				}
			} else {
				this.name = null;
				this.setIds = null;
				this.editItemId = null;
				this.$nextTick(() => {
					this.$refs.form.resetValidation();
				})
			}
		}
	},

	computed: {
		...mapState({
			categories: state => state.categories.categories,
			sets: state => state.sets.sets.filter(c => !c.deleted_at),
		}),

		...mapGetters({
			getCategoryById: 'categories/getCategoryById',
			getSetById: 'sets/getSetById',
		}),

		headers() {
			return [
				{ text: this.$t('headers.name'), value: 'name', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.sets'), value: 'sets_ids', align: 'center' },
				{ text: this.$t('headers.actions'), value: 'actions', align: 'center', sortable: false },
			]
		},

		filterdCategories() {
			return this.filterByName(this.isFiltered, this.categories, this.search);
		}
	},

	methods: {
		getSets(item) {
			if (item.sets_ids && item.sets_ids.length) {
				const names = [];
				item.sets_ids.forEach(id => {
					const set = this.getSetById(id).name;
					names.push(set)
				});

				return names.join(', ')
			}
		},

		submit() {
			if (this.$refs.form.validate()) {
				const actionName = this.editItemId ? 'update' : 'create';
				this.dialogLoading = true;
				this.$store.dispatch(`categories/${actionName}`, {
					id: this.editItemId,
					name: this.name,
					setIds: [this.setIds].flat()
				}).then(() => {
					const message =
						this.editItemId
							? this.$t('$-is-updated-successfully', { name: this.$t('Categories.category') })
							: this.$t('$-is-created-successfully', { name: this.$t('Categories.category') });
					this.$eventBus.$emit('show-snackbar', false, message);
					this.dialog = false;
					this.fetchCategories();
				}).finally(() => {
					this.dialogLoading = false;
				})
			}
		},

		fetchCategories() {
			this.loading = true;
			this.$store.dispatch('categories/fetchAll').finally(() => {
				this.loading = false;
			})
		}
	},

	created() {
		this.fetchCategories();
		if (this.sets.length === 0) {
			this.$store.dispatch('sets/fetchAll')
		}
	}
};
</script>

<style lang="scss" scoped>
.tree-row {
	&:hover {
		background-color: #eee;
	}
	&__summary {
		white-space: pre-line;
	}
}
</style>