import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		sliders: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/sliders').then((response) => {
				commit(types.STORE_SLIDERS, response.data);
				return response.data;
			})
		},
		create({ commit }, { description, url, image }) {
			const formData = new FormData();
			const requestData = [
				{ key: 'description', value: description },
				{ key: 'url', value: url },
				{ key: 'images', value: image },
			];
			
			requestData.forEach(item => {
				if (item.value) {
					formData.append(item.key, item.value);
				}
			});
			return axios.post('/sliders', formData);
		},
		update({ commit }, { id, description, url, image }) {
			const formData = new FormData();
			const requestData = [
				{ key: 'description', value: description },
				{ key: 'url', value: url },
				{ key: 'images', value: image },
			];
			
			requestData.forEach(item => {
				if (item.value) {
					formData.append(item.key, item.value);
				}
			});

			formData.append('_method', 'PUT');

			return axios.post(`/sliders/update/${id}`, formData);
		},
		delete({ commit }, { id }) {
			return axios.post(`/sliders/${id}`, { _method: 'DELETE' })
		},
		restore({ commit }, { id }) {
			return axios.patch(`/sliders/${id}`)
		},
	},

	mutations: {
		[types.STORE_SLIDERS](state, sliders) {
			state.sliders = sliders;
		}
	},

	getters: {
		getSlideById: state => id => state.sliders.find(c => c.id === id),
	}
}