import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import midad, { $eventBus } from './plugins/midad'
import i18n from './i18n/i18n'
import axios from 'axios'
import moment from 'moment/moment'
import { BASE_URL } from './helpers/constants'
import { login } from './configs/routes.config'

Vue.use(VueMeta)

Vue.config.productionTip = false;

window.axios = axios.create({
	baseURL: BASE_URL + '/api',
	headers: { common: { 'accept-language': 'en' } }
})
let controller = {};
if ('AbortController' in window) {
	controller = new AbortController();
	router.beforeEach((to, from, next) => {
		if (from.name) { controller.abort(); controller = new AbortController(); }
		next();
	});
}
window.axios.interceptors.request.use((configs) => {
	configs.signal = controller.signal;
	return configs;
});
window.axios.interceptors.response.use(
	function (response) {
		return response;
	},
	
	function (error) {
		if (error.code === 'ERR_CANCELED') return Promise.reject(error);
		if (error.response && error.response.status === 401) {
			store.dispatch('auth/logout');
			router.replace({ name: login.name })
			$eventBus.$emit('show-snackbar', false, i18n.t('messages.session-is-end'))
		}
		if (router.currentRoute.name !== login.name) {
			$eventBus.$emit('show-snackbar', true, error.response.data.message || error.response.data.error)
		}
		return Promise.reject(error);
	}
);

if ('auth' in localStorage) {
	const remainingMinutes = 
		moment.duration(moment(JSON.parse(localStorage.getItem('expiry-date'))).diff(moment())).minutes();
	if (remainingMinutes < 0) {
		store.dispatch('auth/logout');
	} else {
		store.state.auth.authenticated = true;
		const auth = JSON.parse(localStorage.getItem('auth'))
		window.axios.defaults.headers.common.authorization = `Bearer ${auth.token}`;
		store.state.auth.self.name = auth.name;
		store.state.auth.self.expiresIn = auth.expiresIn;
	}
}
new Vue({
	router,
	store,
	vuetify,
	midad,
	i18n,
	render: h => h(App)
}).$mount('#app')
