import Vue from 'vue'
import { colors } from 'vuetify/lib';
import Vuetify from 'vuetify/lib/framework'
import { mainColor, rtl } from '@/configs/brand.cofig';

Vue.use(Vuetify)

export const customColors = {
	primary: mainColor, // #015931 #083866
	bgColor: '#f3f7f8',
	secondary: '#a7a7a7', // #FFCDD2
	success: '#13a689',
	error: '#ff3334',
	errorLight: '#eeb4b3',
	main: colors.grey.darken3,
	darkGreen: colors.green.darken1
};

export default new Vuetify({
	rtl: rtl,
	theme: {
		themes: {
			light: customColors,
		},
	},
})
