import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		subscriptions: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/subscriptions').then((response) => {
				commit(types.STORE_SUBSCRIPTIONS, response.data);
				return response.data;
			})
		}
	},

	mutations: {
		[types.STORE_SUBSCRIPTIONS](state, subscriptions) {
			state.subscriptions = subscriptions;
		}
	},

	getters: {
		getSubscriptionsById: state => id => state.subscriptions.find(c => c.id === id),
	}
}