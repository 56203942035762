import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		news: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/news').then((response) => {
				commit(types.STORE_NEWS, response.data);
				return response.data;
			})
		},

		fetchBySlug({ commit }, { slug }) {
			return axios.get(`/news/view/${slug}`).then((response) => {
				return response.data;
			})
		},

		/* eslint-disable camelcase */
		create({ commit }, { title, summary, content, date, isFeatured, image, description_seo, keywords_seo }) {
			const formData = new FormData();
			const requestData = [
				{ key: 'title', value: title },
				{ key: 'summary', value: summary },
				{ key: 'content', value: content },
				{ key: 'image', value: image },
				{ key: 'date', value: date },
				{ key: 'description_seo', value: description_seo },
				{ key: 'keywords_seo', value: keywords_seo },
			];

			requestData.forEach(item => {
				if (item.value) {
					formData.append(item.key, item.value);
				}
			});
			
			formData.append('is_featured', isFeatured ? 1 : 0);

			return axios.post('/news', formData).then((response) => {
				return response.data;
			})
		},
		
		update({ commit }, { id, title, summary, content, date, isFeatured, image, description_seo, keywords_seo }) {
			const formData = new FormData();
			const requestData = [
				{ key: 'title', value: title },
				{ key: 'summary', value: summary },
				{ key: 'content', value: content },
				{ key: 'image', value: image },
				{ key: 'date', value: date },
				{ key: 'description_seo', value: description_seo },
				{ key: 'keywords_seo', value: keywords_seo },
			];

			requestData.forEach(item => {
				if (item.value) {
					formData.append(item.key, item.value);
				}
			});
			
			formData.append('is_featured', isFeatured ? 1 : 0);

			formData.append('_method', 'PUT');

			return axios.post(`/news/update/${id}`, formData);
		},
		
		/* eslint-enable camelcase */

		delete({ commit }, { id }) {
			return axios.post(`/news/${id}`, { _method: 'DELETE' })
		},

		restore({ commit }, { id }) {
			return axios.patch(`/news/${id}`)
		},
	},

	mutations: {
		[types.STORE_NEWS](state, news) {
			state.news = news;
		}
	},

	getters: {
		getNewsById: state => id => state.news.find(c => c.id === id),
		getNewsBySlug: state => slug => state.news.find(c => c.slug === slug),
	}
}