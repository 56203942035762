import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		products: []
	},

	actions: {
		fetchAll({ commit }, { page, perPage }) {
			return axios.get('/products', { params: { page, perPage, withTrashed: 0 } }).then((response) => {
				commit(types.STORE_PRODUCTS, response.data);
				return { data: response.data, total: +response.headers['product-count'] };
			})
		},
		fetchBySlug({ commit }, { slug }) {
			return axios.get(`/products/view/${slug}`).then((response) => {
				return response.data;
			})
		},
		/* eslint-disable camelcase */
		create({ commit }, {
			name, summary, code, isFeatured, url, price, secondPrice, dateOfPublication, description, categoryIds, filterIds, keywordIds, image, file, description_seo, keywords_seo
		}) {
			const formData = new FormData();
			const requestData = [
				{ key: 'name', value: name },
				{ key: 'summary', value: summary },
				{ key: 'code', value: code },
				{ key: 'url', value: url },
				{ key: 'price', value: price },
				{ key: 'secondPrice', value: secondPrice },
				{ key: 'Dateofpublication', value: dateOfPublication },
				{ key: 'description', value: description },
				{ key: 'image', value: image },
				{ key: 'file', value: file },
				{ key: 'description_seo', value: description_seo },
				{ key: 'keywords_seo', value: keywords_seo },
			];

			requestData.forEach(item => {
				if (item.value) {
					formData.append(item.key, item.value);
				}
			});
			formData.append('is_featured', isFeatured ? 1 : 0);

			categoryIds.forEach(id => {
				formData.append('category_ids[]', id);
			});
			filterIds.forEach(id => {
				formData.append('filter_ids[]', id);
			});
			keywordIds.forEach(id => {
				formData.append('keyword_ids[]', id);
			});

			return axios.post('/products', formData).then((response) => {
				return response.data;
			})
		},

		update({ commit }, { id, name, summary, code, url, isFeatured, price, secondPrice, dateOfPublication, description, categoryIds, filterIds, keywordIds, image, file, description_seo, keywords_seo }) {
			const formData = new FormData();
			const requestData = [
				{ key: 'name', value: name },
				{ key: 'summary', value: summary },
				{ key: 'code', value: code },
				{ key: 'url', value: url },
				{ key: 'price', value: price },
				{ key: 'secondPrice', value: secondPrice },
				{ key: 'Dateofpublication', value: dateOfPublication },
				{ key: 'description', value: description },
				{ key: 'image', value: image },
				{ key: 'file', value: file },
				{ key: 'description_seo', value: description_seo },
				{ key: 'keywords_seo', value: keywords_seo },
			];
			
			requestData.forEach(item => {
				if (item.value) {
					formData.append(item.key, item.value);
				}
			});
			formData.append('is_featured', isFeatured ? 1 : 0);

			categoryIds.forEach(id => {
				formData.append('category_ids[]', id);
			});
			filterIds.forEach(id => {
				formData.append('filter_ids[]', id);
			});
			keywordIds.forEach(id => {
				formData.append('keyword_ids[]', id);
			});
			formData.append('_method', 'PUT');

			return axios.post(`/products/update/${id}`, formData).then((response) => {
				return response.data;
			})
			// headers: { 'content-type': 'multipart/form-data' } 
		},

		uploadImages({ commit }, { id, images }) {
			const formData = new FormData();
			images.forEach(image => {
				formData.append('images[]', image)
			});

			return axios.post(`/products/${id}/addimages`, formData)
		},

		deleteImage({ commit }, { id, imageId }) {
			return axios.post(`/products/${id}/images/${imageId}`, { _method: 'DELETE' })
		},

		changeState({ commit }, { id }) {
			return axios.post(`/products/changestatus/${id}`, { _method: 'PUT' });
		},

		/* eslint-enable camelcase */
		delete({ commit }, { id }) {
			return axios.post(`/products/${id}`, { _method: 'DELETE' })
		},
		restore({ commit }, { id }) {
			return axios.patch(`/products/${id}`)
		},
	},

	mutations: {
		[types.STORE_PRODUCTS](state, products) {
			state.products = products;
		}
	},

	getters: {
		getFilterById: state => id => state.products.find(c => c.id === id),
		getFilterBySlug: state => slug => state.products.find(c => c.slug === slug),
	}
}