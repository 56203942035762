import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		users: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/users').then((response) => {
				commit(types.STORE_USERS, response.data);
				return response.data;
			})
		},
		fetchById({ commit }, { id }) {
			return axios.get(`/users/view/${id}`).then((response) => {
				return response.data;
			})
		},
		changeState({ commit }, { id }) {
			return axios.post(`/users/changestatus/${id}`, { _method: 'PUT' });
		},
	},

	mutations: {
		[types.STORE_USERS](state, users) {
			state.users = users;
		}
	},

	getters: {
		getUserById: state => id => state.users.find(c => c.id === id)
	}
}