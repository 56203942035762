export default {
	'user-info': 'User info',
	name: 'Name',
	address: 'Address',
	phone: 'Phone',
	email: 'Email',
	date: 'Order date',
	'payment-info': 'Payment info',
	total: 'Total',
	'payment-type': 'Payment type',
	cart: 'Cart info',
}