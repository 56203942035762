export default {
	'basic-info': 'basic info',
	fullname: 'fullname',
	username: 'username',
	email: 'email',
	address: 'address',
	phone: 'phone',
	active: 'active',
	inactive: 'inactive',
	'user-type': 'user type',
	'user-country': 'user country',
	'user-plan': 'user plan',
	'plan-name': 'plan name',
	'subscription-in-plan-date': 'subscription in plan date',
	'subscription-end-in': 'subscription end in',
	'plan-price': 'plan price',

	states: {
		active: 'active',
		inactive: 'inactive',
	},

	types: {
		student: 'student',
		teacher: 'teacher',
		parent: 'parent',
		undefined: 'undefined',
	},

	'user-has-no-plan': 'user has no plan'
}