import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		categories: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/categories').then((response) => {
				commit(types.STORE_CATEGORIES, response.data);
				return response.data;
			})
		},
		fetchAllAsHierarchy({ commit }) {
			return axios.get('/categories/hierarchy').then((response) => {
				commit(types.STORE_CATEGORIES, response.data);
				return response.data;
			})
		},
		fetchBySlug({ commit }, { slug }) {
			return axios.get(`/categories/view/${slug}`).then((response) => {
				return response.data;
			})
		},
		/* eslint-disable camelcase */
		create({ commit }, { name, setIds }) {
			return axios.post('/categories', { name, set_ids: setIds })
		},
		update({ commit }, { id, name, setIds }) {
			return axios.post(`/categories/update/${id}`, { name, set_ids: setIds, _method: 'PUT' })
		},
		/* eslint-enable camelcase */
		delete({ commit }, { id }) {
			return axios.post(`/categories/${id}`, { _method: 'DELETE' })
		},
		restore({ commit }, { id }) {
			return axios.patch(`/categories/${id}`)
		},
	},

	mutations: {
		[types.STORE_CATEGORIES](state, categories) {
			state.categories = categories;
		}
	},

	getters: {
		getCategoryById: state => id => state.categories.find(c => c.id === id),
		getCategoryBySlug: state => slug => state.categories.find(c => c.slug === slug),
	}
}